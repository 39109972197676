@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'LemonMilk';
    font-style: normal;
    font-weight: 400;
    src: local('Lemon/Milk Regular'), url('fonts/LemonMilk.woff') format('woff');
  }

  @font-face {
    font-family: 'LemonMilk';
    font-style: italic;
    font-weight: 400;
    src: url('fonts/LemonMilkitalic.woff') format('woff');
  }

  @font-face {
    font-family: 'LemonMilk';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/LemonMilklight.woff') format('woff');
  }

  @font-face {
    font-family: 'LemonMilk';
    font-style: italic;
    font-weight: 300;
    src: url('fonts/LemonMilklightitalic.woff') format('woff');
  }

  @font-face {
    font-family: 'LemonMilk';
    font-style: normal;
    font-weight: 500;
    src: url('fonts/LemonMilkbold.woff') format('woff');
  }

  @font-face {
    font-family: 'LemonMilk';
    font-style: italic;
    font-weight: 500;
    src: url('fonts/LemonMilkbolditalic.woff') format('woff');
  }
}

.sm-select-container {
  @apply border-none !important;
}

.sm-select__control {
  @apply border-b bg-transparent outline-none border-t-0 border-r-0 border-l-0 rounded-none border-grey-cool pl-4 pb-2 min-h-0 !important;
}

.sm-select__control--is-focused {
  @apply shadow-none border-grey-cool !important;
}

.sm-select__value-container {
  @apply p-0 !important;
}

.sm-select__option {
  @apply bg-transparent !important;
}

.sm-select__option--is-focused {
  @apply font-semibold !important;
}
